.Layout {
  display: flex;
  flex-direction: column;
  background: #f9fafb;
  height: 100vh;
  color: #000;

  &.Automatic {
    .LayoutWrapper {
      @media only screen and (max-width: 51.25rem) {
        .LayoutSidebar {
          min-width: 4.8rem;
          max-width: 4.8rem;
        }

        .SidebarItemText {
          display: none;
        }

        .SidebarItemAmount {
          display: none;
        }
      }

      @media only screen and (max-width: 25.875rem) {
        .LayoutSidebar {
          display: none;
        }

        .SidebarItemText {
          display: none;
        }

        .SidebarItemAmount {
          display: none;
        }
      }
    }
  }

  .LayoutToolBar {
    height: 4.063rem;
    flex-shrink: 0;
  }

  .LayoutWrapper {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 4.063rem);

    .LayoutSidebar {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-shrink: 0;
      flex-basis: 0;
      overflow-y: auto;
      min-width: 11.75rem;

      .LayoutSidebarWrapper {
        height: 100%;
      }

      &.Collapsed {
        min-width: 4.8rem;
        max-width: 4.8rem;

        .SidebarItemText {
          display: none;
        }

        .SidebarItemAmount {
          display: none;
        }
      }

      &.Hidden {
        min-width: 0;
        max-width: 0;

        .SidebarItemText {
          display: none;
        }

        .SidebarItemAmount {
          display: none;
        }
      }
    }

    .LayoutContent {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-grow: 1;
      flex-basis: 0;
      height: calc(100vh - 4.063rem);
      overflow-y: auto;

      .LayoutContentWrapper {
        height: 100%;
      }

      .LayoutFooter {
        position: sticky;
        bottom: 0px;
        width: 100%;
        top: calc(100vh - 4.063rem - 4.063rem);
        min-height: 4.063rem;
        z-index: 300;

        .LayoutFooterWrapper {
          height: 100%;
        }
      }

      @media only screen and (max-width: 37.5rem) {
        .LayoutContentWrapper {
          padding: 2.125rem 1.125rem;
        }
      }
    }
  }

  &.FullSidebar {
    .LayoutWrapper {
      height: 100vh;

      .LayoutContent {
        height: 100vh;

        .LayoutToolBar {
          position: sticky;
          top: 0px;
          width: 100%;
          min-height: 4.063rem;
          z-index: 300;
        }

        .LayoutFooter {
          top: calc(100vh - 4.063rem);
        }
      }
    }
  }
}
