.HomePageWrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  gap: 1rem;
  flex-direction: column;

  .TableMetricsSection {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
  }
}
