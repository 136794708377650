.ToolBar {
  border-bottom: 0.5px solid burlywood;
}

.Dropdown {
  padding: var(--mantine-spacing-sm) 0;
  border-radius: 0;
  border: 0.5px solid #c8c8c8;
}

.Item {
  padding: 0.3rem var(--mantine-spacing-md);
  border-radius: 0;
  color: #8f9aa5;
  font-size: 12px;
}

.ItemSection {
  margin-inline-end: var(--mantine-spacing-sm);
}

.AvatarContainer {
  &:hover {
    cursor: pointer;
  }
}
