.Link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  color: #1f374e;
  background: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm)
    var(--mantine-spacing-xs) var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  &.Collapsed {
    padding: var(--mantine-spacing-xs) 0;
    justify-content: center;
  }

  &:hover {
    background-color: white;
  }

  &[data-active='true'] {
    &,
    &:hover {
      background-color: white;
    }
  }
}

.Tooltip {
  background-color: white;
  color: #1f374e;
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-xl);
  border-radius: 0;
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
}
